import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CatDropdown } from "blocks-react/bedrock/components/Dropdown/Dropdown";
import { CatList } from "blocks-react/bedrock/components/List/List";
import { CatListItem } from "blocks-react/bedrock/components/ListItem/ListItem";
import { CatLoadingIndicator } from "blocks-react/bedrock/components/LoadingIndicator/LoadingIndicator";
import { usageHistoryBarChartData } from "../../pages/Projects/UsageHistoryBarChartData";
import APIError from "../APIError/APIError";
import { BarChart } from "../Charts/BarChart";
const UsageHistory = ({ historyDetailsApiError, historyDetailsApiStatus, refetchHistoryDetails, setUsageHistoryMetricsFilter, usageHistoryDataAvailable, usageHistoryHistogramData, usageHistoryMetricsFilter }) => {
    //Fix for sonarcube code smell
    const renderUsageHistoryOnApiData = () => {
        if (historyDetailsApiStatus === "pending") {
            return (_jsx("div", { className: "flex justify-center items-center h-full min-h-[40vh]", children: _jsx(CatLoadingIndicator, {}) }));
        }
        if (historyDetailsApiStatus === "error") {
            return _jsx(APIError, { refreshHandler: refetchHistoryDetails });
        }
        if (usageHistoryDataAvailable) {
            return (_jsx("div", { className: "self-center usage-history-chart h-full min-h-[40vh]", "data-test": `Barchart-${usageHistoryDataAvailable}`, children: _jsx(BarChart, { autosize: usageHistoryBarChartData.autosize, config: usageHistoryBarChartData.config, data: usageHistoryHistogramData, layout: usageHistoryBarChartData.layout, sliders: usageHistoryBarChartData.sliders, style: usageHistoryBarChartData.style }) }));
        }
        else {
            return (_jsx("div", { className: "flex justify-center items-center h-full min-h-[40vh]", children: _jsx("span", { children: "No data available for the selected date." }) }));
        }
    };
    return (_jsxs("div", { className: `flex flex-col gap-4 m-4 ${historyDetailsApiError ? "h-full" : "justify-between"}`, "data-test": `${usageHistoryMetricsFilter}`, children: [_jsxs("div", { className: "flex gap-3", children: [_jsx("span", { className: "flex-shrink-0", "data-test": "Filter-Metrics-Title", children: "Filter metrics:" }), _jsx("div", { className: "min-w-16", children: _jsx(CatDropdown, { ariaDescribedBy: "dropdown-field-aria-1", "data-test": "Cat-Dropdown", fieldId: "dropdown-1", placeholder: "By Week", size: "sm", value: usageHistoryMetricsFilter, children: _jsxs(CatList, { "data-test": "Cat-List", children: [_jsx(CatListItem, { "data-test": "List-Daily", onBlSelect: () => {
                                            setUsageHistoryMetricsFilter("Daily");
                                        }, value: {
                                            label: "By Day",
                                            value: "Daily"
                                        } }), _jsx(CatListItem, { "data-test": "List-Weekly", onBlSelect: () => {
                                            setUsageHistoryMetricsFilter("Weekly");
                                        }, value: {
                                            label: "By Week",
                                            value: "Weekly"
                                        } }), _jsx(CatListItem, { "data-test": "List-Monthly", onBlSelect: () => {
                                            setUsageHistoryMetricsFilter("Monthly");
                                        }, value: {
                                            label: "By Month",
                                            value: "Monthly"
                                        } })] }) }) })] }), renderUsageHistoryOnApiData()] }));
};
export default UsageHistory;
